<template>
  <el-dialog :title="form.id ? '修改第三方' : '新增第三方'"
             :visible.sync="dialogVisible"
             :append-to-body="true"
             :modal-append-to-body="false"
             width="620px"
             @close="dialogClose"
             @open="dialogOpen">
    <el-form v-if="dialogVisible"
             ref="form"
             v-loading="loading"
             :model="form"
             class="assest-save-form"
             label-width="120px">
      <el-form-item label="第三方名称"
                    prop="name"
                    :rules="[ { required: true, message: '第三方名称不能为空', trigger: 'change' }]">
        <el-input v-model="form.name"
                  maxlength="50" />
      </el-form-item>
      <el-form-item label="类型"
                    prop="typeCode"
                    :rules="[ { required: true, message: '类型不能为空', trigger: 'change' }]">
        <el-select v-model="form.typeCode"
                   class="mr10"
                   placeholder="请选择类型">
          <el-option v-for="(item,index) in typeOptions"
                     :key="index"
                     :label="item.label"
                     :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="合作业务说明">
        <el-input v-model="form.collaborateBusiness"
                  type="textarea"
                  maxlength="500" />
      </el-form-item>
      <el-form-item label="数据的用途">
        <el-input v-model="form.dataUseDescription"
                  type="textarea"
                  maxlength="200" />
      </el-form-item>
      <el-form-item label="是否已签数据使用协议"
                    prop="dataUseProtocol"
                    :rules="[ { required: true, message: '请选择是否已签数据使用协议', trigger: 'change' }]"
                    class="label-line-height-inherit">
        <el-radio-group v-model.number="form.dataUseProtocol">
          <el-radio :label="1">
            是
          </el-radio>
          <el-radio :label="2">
            否
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否已做第三方合规评估"
                    prop="assessStatus"
                    :rules="[ { required: true, message: '请选择是是否已做第三方合规评估', trigger: 'change' }]"
                    class="label-line-height-inherit">
        <el-radio-group v-model.number="form.assessStatus">
          <el-radio :label="1">
            是
          </el-radio>
          <el-radio :label="2">
            否
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="负责人"
                    class="form-item-flex"
                    prop="collaboratePerson"
                    :rules="[ { required: true, message: '负责人不能为空', trigger: 'change' }]">
        <el-input v-model="form.collaboratePerson"
                  class="mr10"
                  placeholder="负责人"
                  type="text"
                  prop="collaboratePerson" />
        <el-input v-model="form.collaboratePersonPhone"
                  v-input-filter
                  placeholder="联系方式"
                  type="text"
                  prop="collaboratePersonPhone" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark"
                  type="textarea"
                  maxlength="200" />
      </el-form-item>
      <el-form-item label="第三方的隐私政策链接"
                    class="label-line-height-inherit">
        <el-input v-model="form.privacyPolicyUrl" />
      </el-form-item>
    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button type="primary"
                 :disabled="loading"
                 @click="submitForm">
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { saveOrUpdateThirdParty, GetThirdPartyById } from '@/api/dataMap/thridParty'

export default {
  data () {
    return {
      typeOptions: [
        { label: '供应商', value: 1 },
        { label: '销售代理及渠道', value: 2 },
        { label: '原厂商', value: 3 },
        { label: '合作单位', value: 4 },
        { label: '监管单位', value: 5 },
        { label: '其他', value: 6 },
      ],
      loading: false,
      dialogVisible: false,
      form: {
        id: '',
        typeCode: '',
        dataUseDescription: '',
        dataUseProtocol: '',
        assessStatus: '',
        collaborateBusiness: '',
        collaboratePerson: '',
        collaboratePersonPhone: '',
        privacyPolicyUrl: '',
        name: '',
        remark: '',
      },
    }
  },
  methods: {
    getAssetsById () {
      GetThirdPartyById({ id: this.form.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          for (const i in this.form) {
            this.form[i] = resData.data[i]
          }
        }
      })
    },
    dialogOpen () {
      if (this.form.id) {
        this.getAssetsById()
      }
    },
    dialogClose () {
      this.form = {
        id: '',
        typeCode: '',
        dataUseDescription: '',
        dataUseProtocol: '',
        assessStatus: '',
        collaborateBusiness: '',
        collaboratePerson: '',
        collaboratePersonPhone: '',
        privacyPolicyUrl: '',
        name: '',
        remark: '',
      }
      this.$refs.form.resetFields()
    },
    // eslint-disable-next-line vue/no-dupe-keys
    submitForm () {
      // eslint-disable-next-line consistent-return
      this.$refs.form.validate(valid => {
        // eslint-disable-next-line no-empty
        if (valid) {
          this.loading = true
          if (!this.form.id) {
            delete this.form.id
          }
          saveOrUpdateThirdParty(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}
.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
</style>
