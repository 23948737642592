import { get, post,downloadByGet } from '@/libs/axios'

// 分页获取第三方合作列表
export const GetThirdPartyListByPage = params => post('/enterprise/thirdParty/Page', params)
export const ImportThirdPartyInfo = params => post('/dataMap/thirdParty/ImportThirdPartyInfo', params)
export const ImportThirdParty = params => post('/third/party/import', params)
export const DownloadThridpartyImportTemplate = () => downloadByGet(`/third/party/import_template?_t=${Date.parse(new Date())}`)
export const ExportThridparty = () => downloadByGet(`/third/party/export?_t=${Date.parse(new Date())}`)
// 用id查询第三方
export const GetThirdPartyById = params => post('/enterprise/thirdParty/GetThirdPartyById', params)

// 批量删除第三方
export const DeleteCollaborateByIds = params => post('/enterprise/thirdParty/Delete', params)
export const saveOrUpdateThirdParty = params => {
  const submitUrl = params.id ? '/enterprise/thirdParty/Update' : '/enterprise/thirdParty/Create'
  return post(submitUrl, params)
}

//
