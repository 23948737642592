<template>
  <div>
    <PageIntroduce
      :title="'第三方管理'"
      :desc="'第三方主要是指在公司或机构的业务运转或内部管理过程中，涉及个人信息处理的产品/服务供应商、合作商。'"
    />
    <el-card class="box-card">
      <div class="filter-wrap flex-between">
        <div class="flex-start mr-1">
          <el-input
            v-model="listQuery.SearchStr"
            placeholder="请输入内容"
            clearable
            @change="handleFilter"
          />
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="handleFilter"
          >
            搜索
          </el-button>
        </div>
        <div
          class="text-right flex-end"
        >
          <!--          <el-button-->
          <!--                  v-if="importPerm"-->
          <!--                  size="small"-->
          <!--                  class="mr10"-->
          <!--                  type="primary"-->
          <!--                  icon="el-icon-upload"-->
          <!--                  @click="importDialogShow"-->
          <!--          >-->
          <!--            导入-->
          <!--          </el-button>-->
          <el-button
            v-if="savePerm"
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="saveDialogShow(1)"
          >
            新增
          </el-button>
        </div>
      </div>
      <el-table
        v-loading="loading"
        border
        fit
        highlight-current-row
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          :show-overflow-tooltip="true"
          align="left"
          label="第三方名称"
        />
        <el-table-column
          prop="typeName"
          width="120"
          label="类型"
        >
          <template slot-scope="scope">
            {{ typeNameObj[scope.row.typeCode] }}
          </template>

        </el-table-column>
        <el-table-column
          prop="collaborateBusiness"
          :show-overflow-tooltip="true"
          label="合作业务说明"
        />
        <el-table-column
          prop="dataUseProtocol"
          label="是否已签数据使用协议"
        >
          <template slot-scope="scope">
            {{ scope.row.dataUseProtocol==1?'是':'否' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="complianceAssess"
          label="是否已做第三方合规评估"
        >
          <template slot-scope="scope">
            {{ scope.row.assessStatus==1?'是':'否' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="collaboratePerson"
          label="第三方负责人"
        />
        <el-table-column
          width="100"
          prop="createTime"
          label="创建时间"
        >
          <template slot-scope="scope">
            {{ formatDateStr(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="80px"
        >
          <template slot-scope="{row}">
            <!--            <el-link-->
            <!--              v-if="readPerm"-->
            <!--              :underline="false"-->
            <!--              class="mr10"-->
            <!--              type="primary"-->
            <!--              @click="detailDialogShow(row.id)"-->
            <!--            >-->
            <!--              <el-tooltip-->
            <!--                content="合作情况"-->
            <!--                placement="top"-->
            <!--                effect="light"-->
            <!--              >-->
            <!--                <i class="el-icon-document" />-->
            <!--              </el-tooltip>-->
            <!--            </el-link>-->
            <el-link
              v-if="updatePerm"
              :underline="false"
              class="mr10"
              type="primary"
              @click="saveDialogShow(2,row.id)"
            >
              <el-tooltip
                content="修改"
                placement="top"
                effect="light"
              >
                <i class="el-icon-edit" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="delPerm"
              :underline="false"
              type="primary"
              @click="delAssest(row.id)"
            >
              <el-tooltip
                content="删除"
                placement="top"
                effect="light"
              >
                <i class="el-icon-delete" />
              </el-tooltip>
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.pageSize"
        @pagination="getList"
      />
    </el-card>
    <ThridInfoSave
      ref="thridSave"
      @handleFilter="handleFilter"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import pagination from '@core/components/pagination/Pagination'
// eslint-disable-next-line import/extensions
import ThridInfoSave from '@/views/thirdPartCompliance/thirdPartInfo/ThridInfoSave'
import { GetThirdPartyListByPage, DeleteCollaborateByIds } from '@/api/dataMap/thridParty'
import {
  error, findBtnPerm, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce'

export default {
  components: {
    pagination,
    ThridInfoSave,
    PageIntroduce,
  },

  data() {
    return {
      typeOptions: [
        { label: '供应商', value: 1 },
        { label: '销售代理及渠道', value: 2 },
        { label: '原厂商', value: 3 },
        { label: '合作单位', value: 4 },
        { label: '监管单位', value: 5 },
        { label: '其他', value: 6 },
      ],
      typeNameObj: {
        1: '供应商',
        2: '销售代理及渠道',
        3: '原厂商',
        4: '合作单位',
        5: '监管单位',
        6: '其他',
      },
      savePerm: findBtnPerm('thirdPartInfo-2'),
      updatePerm: findBtnPerm('thirdPartInfo-3'),
      delPerm: findBtnPerm('thirdPartInfo-4'),
      loading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
      },
      tableData: [],
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.savePerm = verifyBtnPerm(btnPerms, 'thirdPartInfo-2')
      this.updatePerm = verifyBtnPerm(btnPerms, 'thirdPartInfo-3')
      this.delPerm = verifyBtnPerm(btnPerms, 'thirdPartInfo-4')
    })
    this.getList()
  },
  methods: {
    importDialogShow() {
      this.$refs.importThridParty.dialogVisible = true
    },
    delAssest(id) {
      this.$confirm('是否确认删除资产吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        DeleteCollaborateByIds({ id }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.dialogVisible = false
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    detailDialogShow(assestId) {
      this.$router.push({ path: '/cooperation', query: { id: assestId } })
    },
    saveDialogShow(type, assestId) {
      this.$refs.thridSave.dialogVisible = true
      // eslint-disable-next-line eqeqeq
      if (type == 2) {
        this.$refs.thridSave.form.id = assestId
      }
    },
    getList() {
      this.loading = true
      GetThirdPartyListByPage(this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>

<style scoped>

</style>
